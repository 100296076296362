<template>
  <p>欢迎使用后台管理系统</p>
</template>

<script>
export default {

}
</script>

<style>

</style>